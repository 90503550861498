import formInputFieldPropsMixin from './formInputFieldProps.js';

export default {
  mixins: [formInputFieldPropsMixin],
  props: {
    currency: {
      type: String,
      default: '€',
    },
  },
};
