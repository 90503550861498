<template>
  <FormInputWidget
    :id="inputId"
    :notRequired="notRequired"
    :label="label" :hideLabel="hideLabel"
    :hasError="hasError" :errorMsg="errorMsg"
    appendText="%" :appendAction="appendAction"
    :prependText="prependText" :prependAction="prependAction"
  >
    <template v-if="$slots.label" #label="{ label, notRequired }">
      <slot name="label" :label="label" :notRequired="notRequired" />
    </template>

    <template v-if="hasAppendSlot" #append><slot name="append"></slot></template>
    <template v-if="hasAppendContentSlot" #append-content><slot name="append-content"></slot></template>
    <template v-if="hasPrependSlot" #prepend><slot name="prepend"></slot></template>
    <template v-if="hasPrependContentSlot" #prepend-content><slot name="prepend-content"></slot></template>

    <template #default>
      <FormInputPercentage :id="inputId" :disabled="disabled" :notRequired="notRequired" :placeholder="placeholder" :max="max" :min="min" :step="step" :value="value" @input="emitInput" />
    </template>
  </FormInputWidget>
</template>

<script>

import formInputWidgetMixin from '../mixins/formInputWidget.js';
import formInputTextPropsMixin from '../mixins/formInputTextProps.js';

import FormInputWidget from '../Widgets/FormInputWidget.vue';
import FormInputPercentage from '../FormInputPercentage.vue';

export default {
  mixins: [formInputWidgetMixin, formInputTextPropsMixin],
  components: {
    FormInputWidget,
    FormInputPercentage,
  },
};

</script>
